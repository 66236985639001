* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.upperCase {
  text-transform: uppercase;
}

.A4 {
  width: 210mm;
  height: 297mm;
}

.bottom {
  position: relative;
  bottom: 0.2rem;
}

header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.logoBtk {
  width: 23%;
  margin-right: 0.5rem;
}

.btkInfo h1,
p {
  position: relative;
  font-size: 0.8rem;
}

.btkInfo p {
  font-size: 0.665rem;
  font-weight: 500;
  line-height: 1.2;
  width: 25.63rem;
}

.proposalNumber {
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 0.429rem;
  right: 2.52rem;
}

.proposalNumber h1 {
  width: 10rem;
  font-weight: bold;
  position: relative;
  left: 0.5rem;
}

.number {
  font-size: 0.89rem;
  font-family: "Jost", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.date {
  position: relative;
}

.client {
  position: relative;
  bottom: 0.6rem;
}

.client h1 {
  font-size: 0.75rem;
  font-weight: 800;
  margin-bottom: 0.05rem;
}

.client p {
  font-size: 0.77rem;
  font-weight: 500;
  line-height: 1.3;
}

.generalData {
  position: absolute;
  top: 4.8rem;
  left: 28.5rem;
}

.generalData h1 {
  font-size: 0.7rem;
  margin-bottom: 0.3rem;
}

.generalData p {
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2;
}

.secondGeneralData {
  position: absolute;
  top: 6.1rem;
  left: 39.6rem;
}

.secondGeneralData h1 {
  font-size: 0.7rem;
  margin-bottom: 0.1rem;
}

.secondGeneralData p {
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2;
}

.line hr {
  width: 100%;
  border: 0.15rem solid;
  background: var(--preto);
  position: relative;
  bottom: 0.6rem;
}

.productWord h1 {
  position: relative;
  bottom: 0.25rem;
  font-size: 0.63rem;
}

.productsHeader {
  display: flex;
  flex-direction: row;
}

.headerHash {
  font-size: 0.63rem;
  position: relative;
  margin-right: 0.6rem;
}

.headerSku {
  font-size: 0.6rem;
  position: relative;
  margin-right: 6.2rem;
}

.headerDescription {
  font-size: 0.6rem;
  margin-right: 20rem;
}

.headerQuantity {
  font-size: 0.6rem;
  position: relative;
  margin-right: 1.1rem;
}

.headerValue {
  font-size: 0.6rem;
  position: relative;
  margin-right: 3.8rem;
}

.headerSubtotal {
  font-size: 0.6rem;
  position: relative;
  margin-right: 3.5rem;
}

.headerNcm {
  font-size: 0.6rem;
  position: relative;
}

.lineProducts hr {
  position: relative;
  width: 100%;
  border: 1px solid;
  margin-bottom: 0.3rem;
}

.productPDF {
  position: relative;
  display: grid;
  grid-template-columns: 15px 120px 360px 43px 30px 26px 60px 22px 68px 50px;
}

.productIndex {
  position: relative;
  font-size: 0.65rem;
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

.productSkuPDF {
  position: relative;
  font-size: 0.65rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.productDescricaoPDF {
  position: relative;
  font-size: 0.65rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  top: -0.2rem;
  text-transform: uppercase;
}

.pcPDF p {
  font-size: 0.65rem;
  font-weight: 600;
  position: relative;
  bottom: 0.5rem;
  margin-left: 0.3rem;
}

.productQuantidadePDF {
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 0.65rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  bottom: 0.25rem;
}

.productValorPDF {
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 0.65rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  bottom: 0.25rem;
}

.reais p {
  font-size: 0.65rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  position: relative;
  bottom: 0.5rem;
}

.productSubtotalPDF {
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 0.65rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  bottom: 0.25rem;
}

.productNcmPDF {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  font-size: 0.65rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  bottom: 0.25rem;
}

.lineDownBelow hr {
  width: 100%;
  border: 1px solid;
  margin-bottom: 0.2rem;
}

.headerComments {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.headerComments h2 {
  font-size: 0.7rem;
}

.comments h3 {
  position: relative;
  font-size: 0.7rem;
  width: 37rem;
  font-weight: 500;
}

.commentsLine hr,
.commentsLineWithoutDiscount hr {
  border: 1px solid;
  width: 36.7rem;
  position: relative;
  top: 6.7rem;
}

.commentsLineWithoutDiscount hr {
  top: 5.6rem;
}

.priceDetails {
  display: flex;
  justify-content: flex-end;
  font-size: 0.7rem;
  font-weight: 600;
  position: relative;
}

.totalsTable {
  position: relative;
  bottom: 1.2rem;
  left: 3.25rem;
}

.allProducts td,
.taxes td,
.freight td,
.discount td,
.totalValue td {
  padding: 0rem 0rem 0rem 1rem;
}

.totalValue {
  position: relative;
  top: 1rem;
}

.value {
  display: flex;
  justify-content: flex-end;
}

.secondLine,
.secondLineHundred,
.secondLineThousand,
.secondLineTenThousand,
.secondLineHundredThousand {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.secondLine hr,
.secondLineHundred hr,
.secondLineThousand hr,
.secondLineTenThousand hr,
.secondLineHundredThousand hr {
  border: 1px solid;
  position: relative;
  top: 1rem;
}

.footer {
  position: absolute;
  top: 42rem;
}

.footerImage {
  width: 100%;
}

.footer p {
  padding: 0.1rem;
  font-size: 0.65rem;
  font-weight: 500;
  line-height: 0.8rem;
  border: 2px solid;
  margin-bottom: 0.5rem;
}
