.iftaInput,
.iftaLabel {
  border: 0;
  outline: none;
  font-size: 1.3rem;
}

.ifta {
  width: 312px;
  height: 70px;
  position: relative;
}

.inputShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--preto);
}

.iftaInput {
  position: absolute;
  border: 2.5px solid var(--preto);
  background-color: var(--branco);
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 18px;
  transition: transform 0.3s;
}

.iftaInput::placeholder {
  transition: opacity 0.5s;
}

.iftaLabel {
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 100;
  font-weight: 600;
  transition: 0.2s;
  pointer-events: none;
  opacity: 0;
}

.iftaInput:focus::placeholder {
  opacity: 0;
  transition: 0.3s;
}

.iftaInput:focus, 
.iftaInput:not(:placeholder-shown).iftaInput:not(:focus) { 
  transform: translate(-8px, -8px);
  padding: 38px 18px 18px;
  animation: input-animation 0.5s;
  font-size: 1.3rem;
}

.iftaInput:focus + .iftaLabel,
.iftaInput:not(:placeholder-shown).iftaInput:not(:focus) + .iftaLabel {
  font-size: 0.9rem;
  opacity: 1;
  top: 0px;
  left: 12px;
  transition: 0.3s;
  font-weight: bold;
}

@keyframes input-animation {
  0% {
    transform: translate(0);
  }
  40% {
    transform: translate(-9px, -9px);
  }
  60% {
    transform: translate(-7px, -7px);
  }
}
