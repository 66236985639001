.overflow {
  overflow: hidden !important;
  height: 100vh;
}

.AppFreight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--preto);
}

.tittleFreight {
  position: relative;
  bottom: 10rem;
  font-size: 4rem;
  color: var(--laranja);
  z-index: 9999999;
}

.calculatorBox {
  position: relative;
  bottom: 7rem;
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 70%;
  background: var(--laranja);
  border-radius: 1.5rem;
}

.shipimentData {
  position: relative;
  bottom: 1.7rem;
}

.shippingBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;

  padding: 2rem;
  gap: 1rem;
}

.productsBox {
  background-color: var(--branco);
  border-top-right-radius: 1.5rem;
  border-end-end-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 1rem;
}

.productsDetails {
  display: flex;
flex-direction: column;

}

.productsDetails h1 {
  margin-bottom: 0.5rem;
}

.productsInfo {
  display: flex;
  gap: 2rem;
}

.productButton {
  font-size: 1.4rem;
  padding: 0.75rem;
  background-color: var(--laranja);
  color: var(--preto);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.productButton:hover {
  background-color: var(--preto);
  color: var(--branco);
}

.clientMsg {
  width: fit-content;
  position: absolute;
  top: 9.8rem;
  left: 40rem;
}

.productList {
  position: relative;
  bottom: 2.5rem;
  margin: 2rem 0;
  padding: 1rem;
}

.productList h3 {
  width: fit-content;
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
}

.tableContainer {
  max-height: 70%;
  overflow-y: auto;
}

.productTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.productTable th,
.productTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.productTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.productTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.productTable tr:hover {
  background-color: #f1f1f1;
}

.freightResult {
  position: relative;
  bottom: 7rem;
  margin: 2rem 0;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.freightResult h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.freightTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.freightTable th,
.freightTable td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.freightTable th {
  background-color: #f4f4f4;
  color: #333;
}

.freightTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.freightTable tr:hover {
  background-color: #f1f1f1;
}

.freightResult p {
  font-size: 1.1rem;
  color: #666;
  margin-top: 1rem;
}

.calculatorButton {
  position: relative;
  top: 4rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
  width: 18rem;
  font-size: 2rem;
  background-color: var(--preto);
  color: var(--branco);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calculatorButton:hover {
  color: var(--laranja);
}
