.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  gap: 1.5rem;
  padding: 3rem;
  background-color: var(--branco);
  background-image: linear-gradient(to bottom right, #ffffff, #f7f7f7); 
  border-radius: 12px; 
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); 
}

.app h1 {
  font-size: 2.8rem;
  font-weight: bold;
  color: var(--preto); 
  margin-bottom: 1.5rem;
}

.app input,select {
  width: 100%;
  max-width: 350px; 
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.app input:focus, select:focus {
  border-color: var(--laranja);
  box-shadow: 0 0 8px rgba(255, 165, 0, 0.4);
}

.app button {
  font-size: 1.3rem;
  background-color: var(--laranja);
  color: white;
  padding: 0.9rem 2rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.app button:hover {
  background-color: var(--preto);
  transform: scale(1.07); 
  color: var(--laranja);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: #ccc; 
  transform: none; 
  box-shadow: none; 
  color: white;
}

