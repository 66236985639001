.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 1rem 3rem;

  box-sizing: border-box;

  background: #000;

  z-index: 9999999;
}

.navbar {
  display: flex;
  align-items: center;
  height: 3rem;
  width: auto;
  column-gap: 4rem;
}

.LogoDiv {
  display: flex;
  align-items: center;
  height: 100%;
}

.Logo {
  height: 65%;
  cursor: pointer;
}

.navbar-links {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  color: #fafafa;
  font-size: 18px;
  font-weight: bold;

  display: none;
}

.navbar-links a {
  text-decoration: none;
  color: var(--branco);
}

.navbar-buttons {
  display: flex;
  height: 3rem;

  column-gap: 0.5rem;
}

.dropdown {
  display: flex;
  align-items: center;
}

.dropdown .dropdown-title {
  display: flex;
  align-items: center;
  background-color: inherit;
  font-family: inherit;

  display: flex;
  column-gap: 2px;

  color: #fafafa;
  font-size: 18px;
  font-weight: bold;

  border: none;
  outline: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #171717;
  min-width: 110px;
  border-radius: 2px;
}

.dropdown-content a {
  color: var(--branco);
  padding: 1rem;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  color: #949494;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.hamburguer {
  display: none;
  cursor: pointer;
}

.line1,
.line2,
.line3 {
  background-color: #fff;
  width: 25px;
  height: 3px;
  display: block;
  position: relative;
  opacity: 1;
  border-radius: 15%;
  transition: all 0.3s;
}

.navbar-buttons .black-button {
  flex-direction: row;
}

@media only screen and (max-width: 1150px) {
  .navbar {
    column-gap: 2rem;
  }

  .dropdown .dropdown-title {
    font-size: 14px;
  }

  .header button {
    font-size: 14px;
  }

  .navbar-links {
    font-size: 14px;
  }
}

@media only screen and (max-width: 950px) {
  .hamburguer {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    display: none;
  }

  .navbar-links {
    display: none;
  }

  .navbar-buttons {
    display: none;
  }
}

@media only screen and (max-width: 610px) {
  .header {
    padding: 1rem 1rem 0rem 1rem;
  }
}
