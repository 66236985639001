.home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
  }
  
  .buttonsHome button {
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
    font-size: 2rem;
    background-color: var(--laranja);
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  }
  
  .buttonsHome button:hover {
    background-color: var(--preto);
    color: var(--branco);
    transform: scale(1.1);
  }
  