.loadingPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 2rem;
  }
  
  .gif {
    width: 30rem;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
  }
  
  .progressContainer {
    width: 50%;
    height: 0.7rem;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 1rem;
    position: relative;
  }
  
  .progressBar {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.05s linear;
  }
  
  .progressText {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #000;
  }
  